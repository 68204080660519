import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IRecipeExecutionReport extends IBaseEntity {
    id?: number;
    executionDate?: Date;
    executionTime?: any;
    executedBy?: string;
    recipeName?: string;
    warehouseName?: string;
    noOfExecutions?: number;
    totalCost?: number;
}

export class RecipeExecutionReport extends BaseEntity implements IRecipeExecutionReport {
    public id?: number;
    public executionDate?: Date;
    public executionTime?: any;
    public executedBy?: string;
    public recipeName?: string;
    public warehouseName?: string;
    public noOfExecutions?: number;
    public totalCost?: number;

    constructor(recipeExecutionReport?: any) {
        super();
        if (recipeExecutionReport) {
            this.id = recipeExecutionReport.id;
            this.executionDate = recipeExecutionReport.executionDate;
            this.executionTime = recipeExecutionReport.executionTime;
            this.executedBy = recipeExecutionReport.executedBy;
            this.recipeName = recipeExecutionReport.recipeName;
            this.warehouseName = recipeExecutionReport.warehouseName;
            this.noOfExecutions = recipeExecutionReport.noOfExecutions;
            this.totalCost = recipeExecutionReport.totalCost;
        }
    }

    clone(): IBaseEntity {
        return new RecipeExecutionReport(this);
    }
}
